<template>
  <div class="schoolBody">
    <div class="indexTeacher">
      <div class="navList">
        <h4>账户设置</h4>
        <div class="uploadList">
          <button type="button" class="uploadBtn cursorStyle" @click="passWordBox">
            <i class="iconList modify"></i>
            修改密码
          </button>
          <button type="button" class="uploadBtn quit cursorStyle" @click="logout">
            退出登录
          </button>
        </div>
      </div>
      <div class="information">
        <h5 class="inforTitle">
          <span></span>
          <em>个人信息</em>
          <i class="schoolmaster">教师</i>
<!--          <i class="editor"  @click="handleEdit"><b></b>编辑</i>-->
        </h5>
        <el-form :model="formTeacher" :rules="rules" ref="formTeacher">
        <div class="thacherHeder">
            <div class="thacherLogo">
              <div v-if="edit">
                <el-upload
                    list-type="picture-card"
                    :auto-upload="true"
                    :headers="{ token: $store.state.userInfo.token }"
                    :action="constant.UPLOAD_URL + '/file/upload'"
                    :file-list="teacherAvatar"
                    :on-success="onSchoolLogoUploaded"
                    :on-exceed="schoolLogoExceed"
                    :limit="1"
                    class="topPlus">
                   <i v-if="!formTeacher.photo"  class="el-icon-plus avatar-uploader-icon"></i>
                   <img v-else :src="constant.URL + formTeacher.photo" class="avatar" alt="">
                </el-upload>
              </div>
              <div v-else class="licensePic">
                <span class="defaultPic" v-if="!formTeacher.photo"></span>
                <img :src="constant.URL + formTeacher.photo" alt="" v-else>
              </div>
            </div>
            <div class="teacherName">
              <h6>教师积分</h6>
              <span>{{formTeacher.integral}}</span>
            </div>
        </div>
          <el-form-item class="inforText" label="姓名:" prop="name">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formTeacher.name"
                clearable>
            </el-input>
            <p v-else>{{formTeacher.name}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="性别:"  style="display: inline">
            <el-select v-model="formTeacher.sex" placeholder="请选择性别" v-if="edit" >
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
            <p v-else>{{formTeacher.sex ==1?'男':'女'}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="手机:" prop="phone">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formTeacher.phone"
                clearable>
            </el-input>
            <p v-else>{{formTeacher.phone}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="籍贯:">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formTeacher.natives"
                clearable>
            </el-input>
            <p v-else>{{formTeacher.natives?formTeacher.natives:"暂无"}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="身份证:" prop="idCard">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formTeacher.idCard"
                clearable>
            </el-input>
            <p v-else>{{formTeacher.idCard}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="毕业学院:">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formTeacher.graduateSchool"
                clearable>
            </el-input>
            <p v-else>{{formTeacher.graduateSchool?formTeacher.graduateSchool:'暂无'}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="出生日期:">
            <el-date-picker type="date" placeholder="选择日期" v-if="edit" v-model="formTeacher.birthday" style="width: 100%;"></el-date-picker>
            <p v-else>{{formTeacher.birthday?formTeacher.birthday:'暂无'}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="学历:">
            <el-select v-model="formTeacher.education" placeholder="请选择学历" v-if="edit" >
              <el-option label="博士" value="1"></el-option>
              <el-option label="研究生" value="2"></el-option>
              <el-option label="本科" value="3"></el-option>
              <el-option label="大专" value="4"></el-option>
              <el-option label="高中" value="5"></el-option>
            </el-select>
            <p v-else>{{formTeacher.education ? formTeacher.education :'暂无' }}</p>
          </el-form-item>
          <el-form-item class="inforText" label="所带班级:" prop="gradeIds" style="display: inline">
            <el-select
                multiple
                v-model="formTeacher.gradeIds"
                prop
                v-if="edit">
              <el-option v-for="g in atteGradeList"
                         :label="g.name"
                         :value="g.id"
                         :key="g.id">
              </el-option>
            </el-select>
            <p v-else>{{showGrades}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="教师资格证编号:">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formTeacher.teacherCertificateNumber"
                clearable>
            </el-input>
            <p v-else>{{formTeacher.teacherCertificateNumber?formTeacher.teacherCertificateNumber:'暂无'}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="邮箱:">
            <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="formTeacher.email"
                clearable>
            </el-input>
            <p v-else>{{formTeacher.email?formTeacher.email:'暂无'}}</p>
          </el-form-item>
          <el-form-item class="inforText" label="获取证书时间:">
            <el-date-picker type="date" placeholder="选择日期" v-if="edit" v-model="formTeacher.certificateTime" style="width: 100%;"></el-date-picker>
            <p v-else>{{formTeacher.certificateTime ?formTeacher.certificateTime:'暂无'}}</p>
          </el-form-item>
          <div v-if="edit" style="text-align: center">
            <el-button size="small" type="success" class="uploadBtnClear active" @click="onsubmit">保存
            </el-button>
            <el-button slot="trigger"
                       style="margin-left: 10px;"
                       size="small"
                       type="primary"
                       class="uploadBtnClear" >取消
            </el-button>
          </div>
      </el-form>
      </div>

      <amend-password ref="amend" v-if="visibleShow" @visibleColse="visibleColse"></amend-password>
    </div>
  </div>
</template>

<script>
 import AmendPassword from "@/views/user/schoolIndex/amendPassword";

 export default{
   components: {AmendPassword},
   computed:{
     showGrades(){
       const names = []
       for(let i = 0; i<this.formTeacher.gradeIds.length;i++) {
         const id = this.formTeacher.gradeIds[i]
         names.push(this.atteGradeMap[id])
       }
       return names.join()
     }
   },

   data(){
      //验证
     var validateIdCard = (rule,value,callback) => {
       return this.common.validateIdCard(value)
           ? callback()
           : callback(new Error("请输入正确格式身份证号"))
     }
     var validateName = (rule,value,callback)=>{
       return this.common.textNumberCheck(value,25)
           ? callback()
           : callback(new Error("请输入正确格式姓名"))
     }
     var validatePhone = (rule, value, callback) => {
       return this.common.isMobile(value)
           ? callback()
           : callback(new Error("请输入正确格式联系电话"))
     }
     return  {
       edit : false,
       visibleShow: false,
       schoolLogo : '',
       formTeacher :{
         gradeIds: []
       },
       atteTeacher :{},
       listClass:[],

       atteGradeList: [],
       atteGradeMap: {},

       teacherAvatar:[],

       education:{
         '1':'博士',
         '2':'研究生',
         '3':'本科',
         '4':'大专',
         '5':'高中',
       },

       birthTime:'',
       photo:'',
       rules: {
         name: [
           { required: true,validator: validateName , message: '请输入姓名', trigger: 'blur' },
         ],
         phone: [
           { required: true,validator: validatePhone ,message: '请输入手机号', trigger: 'blur' },
         ],
         idCard: [
           { required: true,validator: validateIdCard,message: '请输入身份证号', trigger: 'blur' },
         ],
         gradeIds: [
           { required: true, message: '请选择所带班级', trigger: 'blur'},
         ]
       }
     }
   },
   methods:{


     //编辑教师
     onsubmit(){
       let self = this

       self.$refs.formTeacher.validate((valid) => {

         if (!valid) {
           return
         }

         let data = {
           atteTeacher : {...self.formTeacher}
         }
         data.atteTeacher.sex = parseInt(data.atteTeacher.sex)
         data.atteTeacher.gradeIds = data.atteTeacher.gradeIds.join(',')


         this.api.admin.upadetTeacher(data).then((res) => {
             if (res.flag) {
               this.$notice({
                 type:'success',
                 message: res.message
               }).isShow();
               self.getTeacheInfo()
             } else {
               return this.$notice({
                 message: res.message
               }).isShow();
             }
             self.edit = false
           })

       })

     },
     passWordBox() {
       this.visibleShow = true;
     },
     visibleColse() {
       this.visibleShow = false;
     },
     //退出
     logout() {
       this.$store.dispatch('out', this)
     },
     //头像上传
     onSchoolLogoUploaded(res) {
       this.formTeacher.photo = res.data.filename
     },
     schoolLogoExceed(){
       this.$notice({
         message:'只能上传一张图片'
       }).isShow()
     },
     //查看教师信息
     getTeacheInfo(){
       let that = this
       let data={
            id: this.$store.state.userInfo.teacherId
       }

       // this.api.admin.findGradesOfid({teacherId: 141990}).then(res => {
       //   console.log('老师------', res)
       // })

       this.api.admin.findTeachersInfoById(data).then(res =>{

        //that.photo = res.data.photo

         let formTeacher = {...res.data.atteTeacher}
         let gradeIds = []

         res.data.atteTeacher.gradeIds.split(',').forEach(id => gradeIds.push(parseInt(id)))

         formTeacher.gradeIds = gradeIds

         that.formTeacher = formTeacher

         that.atteGradeList = res.data.atteGradeList
         res.data.atteGradeList.forEach(g => {
           that.atteGradeMap[g.id] = g.name
         })
       })
     },

     //编辑教师
     handleEdit() {
       this.edit = true;
     }
   },

   mounted() {
     this.getTeacheInfo()
   }

 }
</script>

<style lang="scss" scoped>
.schoolBody{
  width: 100%;
  height: 100vh;
  background: #f1f1f1;
}
/deep/.el-upload-list--picture-card .el-upload-list__item{
  width: 68px;
  height: 68px;
 }
.defaultPic{
  display: block;
  width: 64px;
  height:64px;
  border-radius: 50%;
  background: url(../../assets/img/online/Pic_JS_Ks.png) no-repeat;
  background-size: 100% 100%;
}

.indexTeacher{
   width: 1434px;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;
  height: 100vh;
  background:#fff;
  h6{
    color: #333;
    font-size: 16px;
  }
  span{
    font-size: 20px;
    font-weight: bold;
    color: #FFA000;
  }
}
.thacherLogo{
  float: left;
  width: 64px;
  height: 64px;
  border: 1px dashed #333;
  border-radius: 4px;
}
/deep/.avatar-uploader-icon{
  line-height:64px;
}
.teacherName{
   margin-left: 20px;
   float: left;
}

.cursorStyle{
  cursor:pointer;
}
/deep/.degree   .el-input .el-input__inner{
  width: 89px;
}
/deep/ .el-input--mini .el-input__icon{
  line-height: inherit;
}
/deep/.el-form{
  overflow: hidden;

}
.information{
  margin-bottom: 50px;
}


/deep/ .el-textarea {
  width: 300px;
  position: relative;
}

/deep/ .el-input__count {
  position: absolute;
  right: 10px;
}

/deep/ .teachingWidth {
  .el-input__inner {
    width: 300px;
    height: 40px;
    padding-right : 70px;
  }

  .el-textarea__inner {
    width: 300px;
    resize: none;
    margin-top: 10px;
    height: 83px;
  }
}


/deep/ .el-upload--picture-card i {
  line-height: 64px;
}

/deep/ .topPlus {


  .el-upload {

  }
}

.teachingPic {
  height: 80px;

  .el-upload {
    height: 80px;
  }
}


/deep/ .editable {
  width: 98px;

  .el-input {
    float: left;
    width: 98px;
    height: 40px;

    .el-input__inner {
      float: left;
      width: 98px;
      height: 40px;
    }
  }

  .el-textarea__inner {
    width: 300px;
    resize: none;
    margin-top: 10px;
    height: 83px;
  }

}

/deep/ .selectWidth {
  width: 80px;

  .el-select {
    float: left;
    width: 80px;
  }

  .el-input {
    float: left;
    width: 80px;

    .el-input__inner {
      width: 80px;
      padding-right: 20px;
    }
  }
}

//上传学校logo
///deep/.topPlus{
//  margin-top: 12px;
//}
.fl {
  float: left;
}

.fr {
  float: right;
}

.none {
  display: none;
}

.schoolIndex {
  width: 1200px;
  background: #fff;
  padding: 0 40px;
  margin: 0 auto;
}

.navList {
  overflow: hidden;

  h4 {
    float: left;
    font-size: 24px;
    font-weight: bold;
    color: #BA3037;
    margin-top: 30px;
  }

  .uploadList {
    float: right;
    margin-top: 27px;

    .uploadBtn {
      background: #FFFFFF;
      border: 1px solid #2E3C6B;
      border-radius: 15px;
      //padding: 6px 15px 6px 20px;
      margin-left: 7px;
      color: #36467B;
      font-size: 18px;
      width: 120px;
      height: 34px;
      //background:url(../../assets/img/schoolIndex/Btn_Bg_H_Blue.png) no-repeat;
      &:hover {
        //background:url(../../assets/img/schoolIndex/Btn_Bg_H_Blue.png) no-repeat top center;
        //background-size: 100% 100%;
        //width: 148px;
        //height: 63px;
        border: 1px solid transparent;
        color: #fff;
        background: #36467B;
        //padding: 0;
        .iconList {
          display: inline-block;
          width: 16px;
          height: 16px;
        }

        .set {
          background: url(../../assets/img/schoolIndex/Icon_Btn_SetBg_H.png) no-repeat;
          background-size: 100% 100%;
        }

        .transfer {
          background: url(../../assets/img/schoolIndex/Icon_Btn_ZrYz_H.png) no-repeat;
          background-size: 100% 100%;
        }

        .modify {
          background: url(../../assets/img/schoolIndex/Icon_Btn_XgMm_H.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .quit {
      border: 1px solid #BA3037;
      color: #BA3037;
      background: #FFFFFF;
      border-radius: 15px;
      //padding: 6px 15px 6px 20px;
      margin-left: 7px;
      font-size: 18px;
      width:102px;
      height: 34px;

      &:hover {
        background: linear-gradient(232deg, #B93037, #EF525B);
        box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
      }
    }

    .iconList {
      display: inline-block;
      width: 16px;
      height: 16px;
    }

    .set {
      background: url(../../assets/img/schoolIndex/Icon_Btn_SetBg.png) no-repeat;
      background-size: 100% 100%;
    }

    .transfer {
      background: url(../../assets/img/schoolIndex/Icon_Btn_ZrYz.png) no-repeat;
      background-size: 100% 100%;
    }

    .modify {
      background: url(../../assets/img/schoolIndex/Icon_Btn_ChaMm.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}

.inforTitle {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin: 30px 0;
  padding-left: 12px;
  position: relative;

  span {
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #BA3037;
    border-radius: 2px;
    position: absolute;
    top: 7px;
    left: 0;
  }

  em {
    vertical-align: middle;
  }

  i {
    vertical-align: middle;
    margin-left: 15px;
  }

  .schoolmaster {
    padding: 3px 11px;
    height: 24px;
    border: 1px solid #BA3037;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #BA3037;
    font-style: normal;
  }

  .editor {
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #36467B;
    position: absolute;
    top: 0;
    left: 2rem;
    cursor: pointer;

    b {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(../../assets/img/schoolIndex/Icon_Set_Edit.png) no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;

    }
  }
}

.inforName {
  overflow: hidden;
}
.thacherHeder{
  overflow: hidden;
  margin-bottom: 20px;
}
/deep/  .el-form-item__label {
  display: inline-block;
  color: #888;
  font-size: 16px;
  margin-right: 10px;
  line-height: 40px;
  width: 155px;
  text-align: right
}
/deep/.el-form-item__content{
  display: inline-block;
}

.inforText {
  float: left;
  font-size: 16px;
  font-weight: 400;
  margin-right: 30px;
  margin-bottom: 20px;


  .color {
    color: #333;
  }

  p {
    display: inline-block;
    color: #333333;
    line-height: 40px;
    width: 368px;
  }
}

.leftPic {
  img {
    display: inline-block;
    margin-right: 30px;
    width: 100px;
    height: 80px;
  }
}
.uploadBtnClear {
  padding: 10px 32px;
  border: 1px solid #BA3037;
  //background: linear-gradient(232deg, #B93037, #EF525B);
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  color: #BA3037;
  background: #fff;
  margin-top: 20px;
}

.active {
  background: url(../../assets/img/schoolIndex/Btn_Bg_Save_U.png) no-repeat top center;
  background-size: 100% 100%;
  width: 117px;
  height: 60px;
  border: none;
  color: #fff;
}

/deep/ .el-upload__tip {
  font-size: 14px;
  font-weight: 400;
  color: #BA3037;
  margin-top: 13px;
  margin-bottom: 20px;

  img {
    display: inline-block;
    width: 100px;
    height: 80px;
  }
}

.teachers {
  width: 420px;
  margin-bottom: 20px;
  margin-right: 70px;
  height: 138px;
}
.teachers ::after{
  display: block;
  clear: both
}

.radius {
  padding: 12px;
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  border-radius: 2px;
  margin-right: 50px;
  position: relative;
}

.teachersHeader {
  width: 100px;
  height: 140px;
  margin-right: 20px;
  margin-bottom: 4px;
  float: left;
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
  -webkit-transform: translateZ(0);

  img {
    width: 100px;
    height: 100%;
  }

}

.picIcon {
  position: absolute;
  right: -6px;
  top: -5px;
  width: 16px;
  height: 16px;
  background: url(../../assets/img/schoolIndex/close.jpg) no-repeat;
  background-size: 100% 100%;
}

.teacherInfo {
  float: left;

  .tackList {
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;

    .name {
      //width: 98px;
      //height: 40px;
      //margin-right: 20px;
    }

    .sex {
      //width: 70px;
      //height: 40px;
      margin-right: 20px;
    }

    .education {
      //width: 70px;
      //height: 40px;
    }
  }

  .intro {
    color: #888;
    font-size: 14px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      width: 98px;

    }
  }
}

.teacherList {
  margin-bottom: 20px;
  overflow: hidden;

  label {
    display: block;
    color: #888;
    margin-bottom: 14px;
    font-size: 16px;
  }
}

.uploadElem {
  width: 100px;
  height: 138px;
  border: 1px dashed #DDDDDD;
  border-radius: 6px;
  text-align: center;

  .el-icon-plus {
    line-height: 138px;
  }
}

.widthPic {
  height: 80px;
  img{
    width: 100px;
    height:80px;
  }
  .el-icon-plus {
    line-height: 80px;
  }
}

.inputWidth {
  display: inline-block;
  width: 368px;
}

/deep/ .el-input__inner {
  display: inline-block;
  width: 368px;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
}

/deep/ .el-input__suffix, .el-input__suffix-inner {
  line-height: 40px;
}

/deep/ .el-upload--picture-card {
  width: 64px;
  height: 64px;
  line-height: 64px;
  border-radius: 50%;
  img{
    width: 64px;
    height: 64px;
  }
}
.licensePic {
   width: 64px;
  height: 64px;
  border-radius: 50%;
  float: left;
  img {
    width: 100%;
    height: 100%;
  }

  p {
    padding-top: 12px;

    em {
      display: inline-block;
      width: 58px;
      height: 32px;
      background: url(../../assets/img/schoolIndex/Pic_ZzXk_Ks.png) no-repeat;
      background-size: 100% 100%;
    }

    span {
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: #888888;
    }
  }
}
</style>
