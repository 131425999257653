<template>
  <div class="amend">
    <el-dialog :visible.sync="visible" center  @close="close">
      <template slot="title" >
        <div class="upWT">修改密码</div>
        <div class="close"></div>
      </template>
      <el-form :model="form" :rules="rules" label-width="1.4rem"   ref="formList">
        <el-form-item label="姓名" style="margin-bottom:.1rem;color:#888">
          {{ form.contacts }}
        </el-form-item>
        <el-form-item label="手机号" style="margin-bottom:.1rem;color:#888">
          {{ form.contactsMobile }}
        </el-form-item>

        <el-form-item label="旧密码" prop="Oldpassword" style="color:#333">
          <el-input type="password" v-model="form.Oldpassword"></el-input>
        </el-form-item>

        <el-form-item label="新密码" prop="password" style="color:#333">
          <el-input type="password"  v-model="form.password"></el-input>
        </el-form-item>

        <el-form-item label="确认新密码" prop="newPassword" style="color:#333">
          <el-input type="password"  v-model="form.newPassword"></el-input>
        </el-form-item>

        <div style="width: 100%;text-align:center">
            <el-button type="primary" @click="submit" class="btn active">提交</el-button>
            <el-button class="btn" @click="close">取消</el-button>
        </div>


      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "amendPassword",
  props: {
    status:Number
  },
  watch: {},
  // isActive: true,

  data() {
    var validatePassOld = (rule, value, callback) =>{
      if(!value){
        callback(new Error('请输入旧密码'))
      }else{
        if(!this.common.validateLength(value,16)){
          callback(new Error("请输入正确格式密码"));
        }else{
          callback();
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if(!this.common.validateLength(value,16)){
          callback(new Error("请输入正确格式密码"));
        }else{
          if (this.form.newPassword !== '') {
            this.$refs.formList.validateField('newPassword')
          }
          callback()
        }

      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        contacts: '',//用户信息
        contactsMobile: '',//手机号
        password: '',
        newPassword: '',
        Oldpassword: ''
      },
      rules: {
        Oldpassword: [
          {required: true, validator: validatePassOld,trigger: 'blur'},
        ],
        password: [
          {required: true,validator: validatePass,trigger: 'blur'},
        ],
        newPassword: [
          {required: true,validator: validatePass2, trigger: 'blur'},
        ]
      },
      // visibleShow :true,
      visible: true,
      dialogFormVisible: false,
      number: 0,

    };
  }
  ,
//方法
  methods: {
    //获取用户信息
    getUserInfo() {
      let self = this
      if(this.status===2){
        console.log('23242342342');
        var superInfo = JSON.parse(localStorage.getItem('supervisePassWord'))
        console.log('superInfo',superInfo.superviseName)
        self.form.contacts =superInfo.superviseName
        self.form.contactsMobile =superInfo.phone
        console.log('contacts', superInfo.phone)
        return false
      }

      if(this.$store.state.userInfo.teacherId && this.$store.state.userInfo.schoolId ){
        //如果进来是老师账号
        let data={
          id: this.$store.state.userInfo.teacherId
        }
        this.api.admin.findTeachersInfoById(data).then(res =>{
          console.log('如果进来是老师账号',res.data.atteTeacher)
          self.form = res.data.atteTeacher
          self.form.contacts = res.data.atteTeacher.name
          self.form.contactsMobile = res.data.atteTeacher.phone
        })
      }else{
        this.api.user.findUserCenter().then((res) => {
          console.log('修改密码res',res);
          self.form = res.data.atteSchool
        })
      }


    },

    submit() {
      let self = this
      this.$refs.formList.validate((valid) => {
        if (valid) {
          let data = {
            pwd: this.form.Oldpassword,
            newPwd: this.form.password
          }
          this.api.user.updatePassword(data).then((res) => {
            if (res.flag) {
              this.$notice({
                type: "success",
                message: res.message
              }).isShow();
            } else {
              return this.$notice({
                message: res.message
              }).isShow();
            }
            self.visible = false
          })
        }
      })
    },

    tabClass: function (index) {
      this.number = index;
    },

    close(){
      this.visible = false;
      this.$emit('visibleColse', this.visible)
    }
  },

  mounted() {
    this.getUserInfo();
  },

}
</script>

<style lang="scss" scoped>

/deep/ .el-input__inner {
  width: 3.2rem !important;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #BA3037;
  border-radius: 6px;
  background: #fff;
  color: #BA3037;
  font-size: 18px;
  margin: 0 25px;
}

.active {
  background: linear-gradient(232deg, #B93037, #EF525B);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}


/deep/ .el-dialog--center {
  width: 550px;
  .el-form-item {
    margin-bottom: 26px;
  }
}
/deep/ .el-form-item__content{
  margin-left: 0!important;
  float: left;
}

/deep/ .el-form-item__label {
  color: #333;
  font-size: 16px;
  line-height: 45px;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #B93138;
  color: #fff;
  font-size: 18px;
  font-weight:bold;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}
</style>
