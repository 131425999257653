<template>
  <div class="letter_home_page">
    <div class="letter_home_page_bg">
      <div class="table_top">
        <div class="title">站内信</div>
      </div>
      <div class="letter-box">
        <el-table header-align="center"
          header-cell-class-name="message-header_row_style" 
          :data="list" style="width: 100%;border-radius:6px;">
            <el-table-column :align="item.align || 'center'" :show-overflow-tooltip="true" v-for="item in columns" :key="item.prop" :prop="item.prop" :width="item.width" :label="item.label">
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="table_btns">
                        <div class="table_btn" @click="handleView(scope.row)">
                            详情
                        </div>
                        <!-- <div class="table_btn" @click="handleDel(scope.row)">删除</div> -->
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <comPagination :params="params" @changePage="changePage"></comPagination>
      </div>
      
    </div>
    
  </div>
</template>

<script>
//   import myTable from '@/components/admin/table.vue'
  import comPagination from '@/components/admin/pagination.vue'
  export default {
    name: 'letter',
    components: {
      comPagination,
    //   myTable
    },
    data() {
      return {
        //班级列表
        columns: [
        {
          prop: 'messageId',
          label: '编号',
        },
        {
          prop: 'messageTitle',
          label: '消息标题',
        },
        {
          prop: 'updatetime',
          label: '发送时间',
        },
        // {
        //   prop: 'phone',
        //   label: '状态',
        // }
        ],
        list: [],
        //分页
        params: {
          page: 0,
          size: 10,
          total: 0
        }
      }
    },
    created() {},
    mounted() {
      this.init()
    },
    methods: {
      //初始化
      init() {
        this.getInfo()
      },
      getInfo() {
        let data = {
          userId: this.$store.state.userInfo.schoolId,
        }
        this.api.admin.messageNotice(this.params, data).then((res) => {
          console.log(res)
          this.params.total = res.data.total
          this.list = res.data.list.map(item => {
            let data = { ...item, ...item.messageNotice }
            data.createtime = this.common.dateFormat(data.createtime * 1000)
            data.updatetime = this.common.dateFormat(data.updatetime * 1000)
            return data
          })
        })
      },
      handleView(val) {
        console.log(val)
        this.$router.push({
          path: '/nletter/nletterDetail',
          query: {
            ...val
          }
        })
      },
      handleDel(val) {
        console.log(val)
        this.$confirm('此操作将删除该消息, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            let data = {
              messageId: val.messageId,
              userId: val.userId
            }
            this.api.admin.deleteStatus(data).then(res => {
              if (res.flag) {
                this.$notice({
                  type: 'success',
                  message: '删除成功!'
                }).isShow()
              } else {
                this.$notice({
                  type: 'warn',
                  message: '删除失败!'
                }).isShow()
              }
              this.getInfo()
            })

          })
          .catch(() => {
            this.$notice({
              type: 'warn',
              message: '已取消删除'
            }).isShow()
            this.getInfo()
          })
      },

      //分页 todo 待测试
      changePage(val) {
        this.params.page = val
        this.getInfo()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .letter_home_page {
      background:#F1F1F1;

      .letter_home_page_bg{
        width:1354px;
        margin:0 auto;
        padding:30px 40px 120px 40px;
        background:#FFFFFF;
      }
      .letter-box{
        margin-bottom:30px;
        box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
        border-radius: 4px;

        .dialog_block{
            text-align: center;
        }
      }
        .table_top{
            margin-bottom:30px;
            .title{
                font-size: 24px;
                font-weight: bold;
                color: #BA3037;
            }
        }
        .table_btn{
          border:none;
          font-size: 14px;
          font-weight: 400;
          color: #BA3037;
          opacity: 0.9;
        }
  }
</style>
<style lang="scss">
.message-header_row_style{
    background:#BA3037 !important;
    color:#FFFFFF;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    opacity: 0.9;
}

</style>